.Spreadsheet {
  position: relative;
  overflow: visible;
  background: white;
  color: black;
  display: inline-block;
}

.Spreadsheet .ActiveCell {
  padding: 4px;
  position: absolute;
  border: 2px solid #4285f4;
  box-sizing: border-box;
}

.Spreadsheet .ActiveCell.edit {
  background: white;
}

.SpreadsheetTable {
  border-collapse: collapse;
  table-layout: fixed;
}

.Spreadsheet td,
.Spreadsheet .ActiveCell {
  cursor: cell;
}

.Spreadsheet td,
.Spreadsheet td input {
  outline: none;
}

.Spreadsheet td,
.Spreadsheet th {
  min-width: 6em;
  min-height: 1.9em;
  height: 1.9em;
  max-height: 1.9em;
  padding: 4px;
  border: 1px solid rgb(231, 231, 231);
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
}

.Spreadsheet th {
  background: #f5f5f5;
  color: #999;
  text-align: center;
  font: inherit;
}

.Spreadsheet .ActiveCell.edit {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.Spreadsheet td.readonly {
  color: #999;
}

.Spreadsheet .DataEditor,
.Spreadsheet .DataEditor input {
  width: 98%;
  height: 98%;
}

.Spreadsheet .DataEditor input {
  font: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
}

.Spreadsheet .selected {
  background: rgba(160, 195, 255, 0.2);
  border: 2px #4285f4 solid;
}

.Spreadsheet .selected.dragging {
  border: none;
}

.Spreadsheet .copied {
  border: 2px #4285f4 dashed;
}

.Spreadsheet .boolean {
  text-align: center;
}
